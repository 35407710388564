import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Naming Your CSS Grid Lines",
  "date": "2017-10-02",
  "promo": "grids",
  "description": "What you get from naming your grid lines, and an easy way to remember the syntax.",
  "color": "#ff7682"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`CSS Grid is crazy powerful and bring us lot of `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`awesome new tools`}</a>{`. One of the coolest new things we get is the ability to `}<strong parentName="p">{`name our grid lines`}</strong>{`. But `}<em parentName="p">{`why`}</em>{` you'd want to name them and the syntax for doing so can be a bit confusing.`}</p>
    <h2>{`What Can You Do With Named Grid Lines?`}</h2>
    <p>{`Naming grid lines gives you `}<em parentName="p">{`hooks`}</em>{` into your grid where you can `}<em parentName="p">{`attach`}</em>{` your content. Let's take a look at naming some vertical lines with the `}<inlineCode parentName="p">{`grid-template-columns`}</inlineCode>{` property. We'll call our outer lines `}<inlineCode parentName="p">{`outer-start`}</inlineCode>{` and `}<inlineCode parentName="p">{`outer-end`}</inlineCode>{`, and name the middle two lines `}<inlineCode parentName="p">{`center-start`}</inlineCode>{` and `}<inlineCode parentName="p">{`center-end`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  grid-template-columns: [outer-start] 1fr [center-start] 1fr [center-end] 1fr [outer-end];
}
`}</code></pre>
    <p>{`Once they lines are named we're able to attach content to them using the `}<inlineCode parentName="p">{`grid-columns`}</inlineCode>{` property. In this example we're telling the `}<inlineCode parentName="p">{`purple`}</inlineCode>{` item to start at the `}<inlineCode parentName="p">{`outer-start`}</inlineCode>{` line and end at the `}<inlineCode parentName="p">{`outer-end`}</inlineCode>{` line.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      grid-template-columns: [outer-start] 1fr
                             [center-start] 1fr 
                             [center-end] 1fr [outer-end];
      grid-template-rows: 1fr 1fr;
    }\n
    .purple {
      grid-column: outer-start / outer-end;
    }\n
    .orange {
      grid-column: center-start / center-end;
    }
  `} fixedCSS={`
  body {
    margin: 0;
  }
  .container {
      border: 5px solid black;
      height: 400px;
      margin: 20px 0;
    }
  `} html={`
    <div class="container">
      <div class="item purple"></div>  
      <div class="item orange"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`Orange is doing the same thing except with the center lines.`}</p>
    <p>{`Once you've named your lines, you can use these names to `}<strong parentName="p">{`position`}</strong>{` and `}<strong parentName="p">{`size`}</strong>{` your grid items! Here we're telling the `}<inlineCode parentName="p">{`grey`}</inlineCode>{` item to start at the `}<inlineCode parentName="p">{`outer-start`}</inlineCode>{` line, and go until the `}<inlineCode parentName="p">{`center-end`}</inlineCode>{` line. Blue is starting at `}<inlineCode parentName="p">{`center-start`}</inlineCode>{` and ending at the `}<inlineCode parentName="p">{`outer-end`}</inlineCode>{` line.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      grid-template-columns: [outer-start] 1fr
                             [center-start] 1fr 
                             [center-end] 1fr [outer-end];
      grid-template-rows: 1fr 1fr;
    }\n
    .grey {
      grid-column: outer-start / center-end;  
    }\n
    .blue {
      grid-column: center-start / outer-end;
    }
  `} fixedCSS={`
  body {
    margin: 0;
  }
  .container {
      height: 400px;
    }
  `} html={`
    <div class="container">
      <div class="item grey"></div>
      <div class="item blue"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`You can name your horizontal grid lines as well using `}<inlineCode parentName="p">{`grid-template-rows`}</inlineCode>{`. This lets you use named lines to position/size your grid items vertically. In this example we tell the `}<inlineCode parentName="p">{`blue`}</inlineCode>{` item to attach to the center vertical lines, to start at the `}<inlineCode parentName="p">{`top`}</inlineCode>{` horizontal line and go all the way to the `}<inlineCode parentName="p">{`bottom`}</inlineCode>{` horizontal line.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      grid-template-columns: 
        [outer-start] 1fr 
        [center-start] 1fr 
        [center-end] 1fr 
        [outer-end];
      grid-template-rows: [top] 1fr 1fr 1fr [bottom];
    }\n
    .blue {
      grid-column: center-start / center-end;
      grid-row: top / bottom;
    }
  `} fixedCSS={`
  body {
    margin: 0;
  }
  .container {
      height: 400px;
    }
  `} html={`
    <div class="container">
      <div class="item grey"></div>
      <div class="item purple"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`Once you get the hang of it, handling positioning and sizing with named grid lines is a snap.`}</p>
    <h2>{`But That Syntax Tho`}</h2>
    <p>{`The syntax for naming grid lines can seem confusing at first. I've found that it helps tremendously to think of the syntax like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/grid-line-syntax.jpg",
        "alt": "css grid line syntax"
      }}></img></p>
    <p>{`Where a `}<strong parentName="p">{`track`}</strong>{` is a column or a row, and the `}<strong parentName="p">{`lines are optional`}</strong>{`. The confusion comes in because we're actually doing two things in a single line:`}</p>
    <ol>
      <li parentName="ol">{`Naming our lines (`}<em parentName="li">{`not`}</em>{` our tracks)`}</li>
      <li parentName="ol">{`Sizing our tracks (columns or rows)`}</li>
    </ol>
    <p>{`So going back to our very first example where we named the `}<inlineCode parentName="p">{`outer`}</inlineCode>{` and `}<inlineCode parentName="p">{`center`}</inlineCode>{` lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  grid-template-columns: [outer-start] 1fr [center-start] 1fr [center-end] 1fr [outer-end];
}
`}</code></pre>
    <p>{`We can think of that syntax like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/named-lines-example.jpg",
        "alt": "css grid line syntax example"
      }}></img></p>
    <p>{`Think of it as `}<em parentName="p">{`line`}</em>{`, `}<em parentName="p">{`track`}</em>{`, `}<em parentName="p">{`line`}</em>{`, `}<em parentName="p">{`track`}</em>{`, etc. It always starts with a line and ends with a line.`}</p>
    <p>{`It's also important to remember that line names are surrounded by brackets `}<inlineCode parentName="p">{`[]`}</inlineCode>{` only when they're defined, not when they're used to attach items to them.`}</p>
    <h2>{`Names are Optional`}</h2>
    <p>{`Say we didn't want to name the `}<inlineCode parentName="p">{`outer`}</inlineCode>{` lines anymore but just want to give the `}<inlineCode parentName="p">{`center`}</inlineCode>{` lines names:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  grid-template-columns: 1fr [center-start] 1fr [center-end] 1fr;
}
`}</code></pre>
    <p>{`We can think of it the same way:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/named-lines-example2.jpg",
        "alt": "css grid line syntax example"
      }}></img></p>
    <p>{`We just didn't name the outer lines this time. It's still helpful to remember that the lines are there even if we didn't give them names.`}</p>
    <h2>{`Multiple Names`}</h2>
    <p>{`Just as a heads up, you can give grid lines as many names as you want. This is helpful since lines sit between two different tracks and border on grid `}<inlineCode parentName="p">{`areas`}</inlineCode>{` which we'll get to in another post. The syntax for multiple names is just a space between the names inside of the bracket:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  grid-template-columns: 1fr [center-start content-start] 1fr [center-end content-end] 1fr;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/named-lines-example3.jpg",
        "alt": "css grid lines with multiple names"
      }}></img></p>
    <h2>{`Automatic Names`}</h2>
    <p>{`There's a tiny little robot inside of each browser that automatically names your grid lines for you - even if you've already named them yourself! It starts at `}<inlineCode parentName="p">{`1`}</inlineCode>{` and adds a numeric name to each line, in order. So our example above would actually end up with these names:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/named-lines-example4.jpg",
        "alt": "css grid lines with multiple names"
      }}></img></p>
    <p>{`We can even use these automatic names for positioning:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      grid-template-columns: 
        1fr [center-start] 1fr [center-end] 1fr;
      grid-template-rows: 1fr 1fr;
    }\n
    .purple {
      grid-column: 1 / 4;
    }\n
    .orange {
      grid-column: 2 / 3;
    }
  `} fixedCSS={`
  body {
    margin: 0;
  }
  .container {
      height: 400px;
    }
  `} html={`
    <div class="container">
      <div class="item purple"></div>  
      <div class="item orange"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`But it's usually a better practice to give your lines meaningful names rather than using the automatic numbers, since the numbers will change when you change your grid.`}</p>
    <p>{`And that's it! To get some solid practice and commit this stuff to memory join us in `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`. Once you get the hang of the syntax you'll be able to size and position any grid items using this awesome new technique.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      